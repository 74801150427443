import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { grey, red } from '@mui/material/colors';
import { TopAppBar } from './TopAppBar';

export const Redirector = () => {
  // get current path
  const { pathname } = useLocation();

  // split path into array and remove empty strings (falsey values)
  // const pathArray = pathname.split('/').map(path => path !== '' && path).filter(path => path);

  const newLink = 'https://www.anotemos.org' + pathname;
  let linkRef;

  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  }

  React.useEffect(() => {
    setTimeout(() => {
      linkRef && linkRef.click();
    }, 5 * 1000);
  }, [linkRef]);

  return (
    <React.Fragment>
      <TopAppBar />
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6, px: { xs: 2 } }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
          We moved Anotemos to a new domain!
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'center' }} gutterBottom>
          You should be redirected automatically in a few seconds.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 10 }}>
          <Box sx={{
            display: 'inline-block', p: 2, backgroundColor: grey[200],
            border: 2, borderColor: grey[300],
            borderTopLeftRadius: 30, borderTopRightRadius: 5,
            borderBottomRightRadius: 30, borderBottomLeftRadius: 5
          }}>
            <Typography variant="h6" sx={{ textAlign: 'center', color: red[400] }}>
              Please make sure to allow popups for this site.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              If you are not redirected automatically, follow this link:
              <br />
              <Link ref={link => linkRef = link} href={newLink} target='_blank' onClick={goHome}>{newLink}</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}
