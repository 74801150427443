import React from 'react';
import { AppBar, Box, Button, CssBaseline, GlobalStyles, Link, Toolbar } from '@mui/material';

export const TopAppBar = () => {
    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <AppBar
                position="fixed"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar variant='dense' sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <Box>
                        <Link sx={{ display: 'flex', alignContent: 'center' }} href="/">
                            <img src="/anotemos_logo_sm.png" alt="Anotemos Logo" height="60" />
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button href="https://www.gripumich.org/contact" target='_blank' variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                            Contact Us
                        </Button>
                        <Button href="https://www.anotemos.org/" target='_blank' variant="contained" sx={{ my: 1, mx: 1.5 }}>
                            Login
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar variant='dense' />
        </React.Fragment>
    );
}
